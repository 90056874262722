import {
  useState,
  useRef,
  useImperativeHandle,
  ReactNode,
  forwardRef,
  ForwardRefRenderFunction,
  SyntheticEvent,
  MouseEventHandler,
  ComponentProps,
  CSSProperties,
} from 'react';
import Link from 'next/link';
import Image from 'next/legacy/image';

import colours from './colours';

import facebookIcon from '@public/img/icons/facebook.png';
import appleIcon from '@public/img/icons/apple.png';

type SpinnerType = {
  isShown: boolean;
  rightPosition?: string;
};

export const Spinner = ({
  isShown,
  rightPosition,
}: SpinnerType): JSX.Element => (
  <>
    <span className={isShown ? 'spinner show' : 'spinner'} />
    <style jsx>{`
      @keyframes spin {
        0% {
          animation-timing-function: cubic-bezier(
            0.5856,
            0.0703,
            0.4143,
            0.9297
          );
          transform: rotate(0);
        }

        100% {
          transform: rotate(360deg);
        }
      }

      .spinner {
        animation: spin 1s infinite linear;
        top: 50%;
        left: auto;
        right: ${rightPosition};
        position: absolute;
        width: 1em;
        height: 1em;
        margin: -0.5em;
        opacity: 0;
        z-index: -1;
        transition: all 0.3s;
        transition-timing-function: ease-in;
        display: inline-block;
        box-sizing: content-box;
        transform-origin: 50% 50%;
      }

      .spinner::after {
        content: ' ';
        display: block;
        width: 2em;
        height: 2em;
        box-sizing: border-box;
        transform-origin: 0 0;
        transform: translateZ(0) scale(0.5);
        backface-visibility: hidden;
        border-radius: 50%;
        border: 0.3em solid ${colours.white};
        border-left-color: transparent;
      }

      .spinner.show {
        opacity: 1;
        z-index: auto;
        visibility: visible;
      }
    `}</style>
  </>
);

Spinner.defaultProps = {
  isShown: false,
  rightPosition: '1.25em',
};

type NavigationButtonType = {
  href?: string;
  children: ReactNode;
  backgroundColor: string;
  color: string;
  height?: number;
  width: string;
  paddingTop?: number;
  paddingSide?: number;
  onClick?: (SyntheticEvent: SyntheticEvent) => void | Promise<void>;
  fontSize: number;
  isLoading: boolean;
  isExternalLink: boolean;
  borderRadius: number;
  useLink: boolean;
};

const navigationButtonClass =
  'whitespace-no-wrap leading-[18px] hover:translate-y-[-1px] active:translate-y-[1px] relative inline-block cursor-pointer select-none text-center font-cera font-medium no-underline transition-all';

const NavigationButton = ({
  href,
  children,
  backgroundColor,
  color,
  height = 44,
  width,
  paddingTop = 14,
  paddingSide = 28,
  onClick,
  isLoading,
  isExternalLink,
  fontSize,
  borderRadius,
  useLink,
}: NavigationButtonType): JSX.Element => {
  const style = {
    height: `${height}px`,
    width: `${width === '100%' ? '100%' : width}`,
    paddingTop: `${paddingTop}px`,
    paddingLeft: `${paddingSide}px`,
    paddingRight: `${paddingSide}px`,
    borderRadius: `${borderRadius}px`,
    fontSize: `${fontSize}px`,
    color: `${color}`,
    backgroundColor: `${backgroundColor}`,
  };

  return useLink && href ? (
    <Link href={href} className={navigationButtonClass} style={style}>
      {children}
    </Link>
  ) : (
    // eslint-disable-next-line react/jsx-no-target-blank
    <a
      href={href}
      onClick={onClick}
      target={isExternalLink ? '_blank' : ''}
      rel={isExternalLink ? 'noopener noreferrer' : ''}
      className={navigationButtonClass}
      style={style}
    >
      <Spinner isShown={isLoading} />
      <span>{children}</span>
    </a>
  );
};

NavigationButton.defaultProps = {
  backgroundColor: colours.snBlue,
  color: colours.white,
  isExternalLink: false,
  fontSize: 16,
  width: '100%',
  borderRadius: 2,
  useLink: false,
  isLoading: false,
};

type BlueSubmitButtonType = {
  children: ReactNode;
  isSubmitting?: boolean;
  buttonType?: 'button' | 'submit' | 'reset';
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  isInverted?: boolean;
  marginTop?: number;
  marginBottom?: number;
  width?: string;
  borderRadius?: number;
  isDisabled?: boolean;
  customStyle?: CSSProperties;
};

type BlueSubmitButtonConfigType = {
  shakeButton: () => void;
  blur: () => void;
};

const BlueSubmitButton: ForwardRefRenderFunction<
  BlueSubmitButtonConfigType,
  BlueSubmitButtonType
> = (
  {
    children,
    isSubmitting,
    buttonType,
    onClick,
    isInverted,
    marginTop,
    marginBottom,
    width,
    borderRadius,
    isDisabled,
    customStyle,
  }: BlueSubmitButtonType,
  ref,
) => {
  const [shakeButton, setShakeButton] = useState(false);
  const buttonRef = useRef<HTMLButtonElement>(null);

  const buttonColour = isDisabled
    ? 'bg-blue-100'
    : isInverted
    ? 'bg-white'
    : 'bg-blue';

  const textColour = isDisabled
    ? 'text-white'
    : isInverted
    ? 'text-blue'
    : 'text-white';

  const border = isDisabled ? 'border-blue-100' : 'border-blue';

  useImperativeHandle(ref, () => ({
    shakeButton: () => {
      setShakeButton(true);
      setTimeout(() => setShakeButton(false), 400);
    },
    blur: () => {
      if (buttonRef.current) {
        buttonRef.current.blur();
      }
    },
  }));

  return (
    <>
      <button
        type={buttonType || 'submit'}
        disabled={isSubmitting || isDisabled}
        onClick={onClick}
        className={`border-[1px] font-cera text-[16px] font-medium leading-[20px] tracking-[-0.22px]${
          shakeButton ? ' shake ' : ''
        } ${buttonColour} ${textColour} ${border}`}
        style={customStyle}
        ref={buttonRef}
      >
        <Spinner isShown={isSubmitting} />
        <span>{children}</span>
      </button>
      <style jsx>{`
        button {
          text-decoration: none;
          -webkit-font-smoothing: antialiased;
          display: block;
          text-align: center;
          cursor: pointer;
          user-select: none;
          background-image: none;
          border-radius: ${borderRadius}px;
          transition: all 0.15s ease;
          padding-top: 12px;
          padding-bottom: 12px;
          position: relative;
          margin-top: ${marginTop || 0}px;
          margin-bottom: ${marginBottom || 0}px;
          min-width: 200px;
          ${width !== undefined ? `width: ${width};` : ''}
        }

        button:hover {
          transform: translateY(-1px);
          text-decoration: none;
          outline: 0;
        }

        button:focus {
          outline: none;
          text-decoration: none;
          outline-offset: -2px;
        }

        button:active {
          transform: translateY(1px);
        }

        @keyframes inputShake {
          0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
          }

          12.5% {
            -webkit-transform: translateX(-6px);
            transform: translateX(-6px);
          }

          37.5% {
            -webkit-transform: translateX(5px);
            transform: translateX(5px);
          }

          62.5% {
            -webkit-transform: translateX(-3px);
            transform: translateX(-3px);
          }

          87.5% {
            -webkit-transform: translateX(2px);
            transform: translateX(2px);
          }

          to {
            -webkit-transform: translateX(0);
            transform: translateX(0);
          }
        }

        button.shake {
          animation-duration: 0.4s;
          animation-timing-function: ease-in-out;
          animation-name: inputShake;
        }
      `}</style>
    </>
  );
};

const BlueSubmitButtonWithRef = forwardRef(BlueSubmitButton);

BlueSubmitButtonWithRef.defaultProps = {
  borderRadius: 4,
  isDisabled: false,
};

type BackButtonType = {
  children: ReactNode;
  buttonType: 'button' | 'submit' | 'reset';
  onClick?: () => void | Promise<void>;
  marginTop: number;
  marginBottom: number;
};

const BackButton = ({
  children,
  buttonType,
  onClick,
  marginTop,
  marginBottom,
}: BackButtonType): JSX.Element => (
  <>
    <button
      type={buttonType}
      onClick={onClick}
      className="flex font-cera text-[16px] font-medium leading-[20px] tracking-[-0.22px] text-blue"
    >
      <span className="back" />
      <span>{children}</span>
    </button>
    <style jsx>{`
      button {
        text-decoration: none;
        -webkit-font-smoothing: antialiased;
        text-align: center;
        cursor: pointer;
        user-select: none;
        background-image: none;
        background-color: transparent;
        border: none;
        transition: all 0.15s ease;
        padding-top: 12px;
        padding-bottom: 12px;
        position: relative;
        margin-top: ${marginTop}px;
        margin-bottom: ${marginBottom}px;
      }

      button:hover {
        transform: translateY(-1px);
        text-decoration: none;
        outline: 0;
      }

      button:focus {
        outline: none;
        text-decoration: none;
        outline-offset: -2px;
      }

      button:active {
        transform: translateY(1px);
      }

      .back {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        position: relative;
        top: 3px;
        background-image: url('/img/back.png');
        background-size: 20px auto;
        background-repeat: no-repeat;
      }
    `}</style>
  </>
);

BackButton.defaultProps = {
  buttonType: 'button',
  marginTop: 0,
  marginBottom: 0,
};

interface IndividualSocialSigninButtonProps {
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
  isSubmitting?: boolean;
  isDisabled?: boolean;
}

interface SocialSigninButtonProps extends IndividualSocialSigninButtonProps {
  customStyle: CSSProperties;
  icon: ComponentProps<typeof Image>['src'];
  provider: 'Facebook' | 'Apple';
}

const SocialSigninButton = ({
  onClick,
  customStyle,
  icon,
  provider,
  isSubmitting,
  isDisabled,
}: SocialSigninButtonProps): JSX.Element => (
  <BlueSubmitButtonWithRef
    buttonType="button"
    onClick={onClick}
    width="250px"
    isSubmitting={isSubmitting}
    isDisabled={isDisabled}
    customStyle={customStyle}
  >
    <span className="flex items-center justify-between px-3 font-cera text-[14px] font-medium leading-[14px] tracking-[-0.22px]">
      <Image src={icon} width={18} height={18} />
      <span className="ml-3 grow">{`Continue with ${provider}`}</span>
    </span>
  </BlueSubmitButtonWithRef>
);

const FacebookSigninButton = ({
  onClick,
  isSubmitting,
  isDisabled,
}: IndividualSocialSigninButtonProps): JSX.Element => (
  <SocialSigninButton
    onClick={onClick}
    icon={facebookIcon}
    customStyle={{
      backgroundColor: colours.facebookBlue,
      borderColor: colours.facebookBlue,
    }}
    provider="Facebook"
    isSubmitting={isSubmitting}
    isDisabled={isDisabled}
  />
);

const AppleSigninButton = ({
  onClick,
  isSubmitting,
  isDisabled,
}: IndividualSocialSigninButtonProps): JSX.Element => (
  <SocialSigninButton
    onClick={onClick}
    icon={appleIcon}
    customStyle={{
      backgroundColor: colours.appleBlack,
      borderColor: colours.appleBlack,
    }}
    provider="Apple"
    isSubmitting={isSubmitting}
    isDisabled={isDisabled}
  />
);

export {
  NavigationButton,
  BlueSubmitButtonWithRef as BlueSubmitButton,
  BackButton,
  FacebookSigninButton,
  AppleSigninButton,
};
